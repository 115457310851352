
export default {
    data: function() {
        return {
            countryCode: this.$store.state.sessionStorage.countryCode,
            routename: this.$nuxt.$route.name,
            selectedLang:'',
            merchantSettings:''
        }
    },
    head() {
    return {
      link: [
    //       { rel: 'icon', type: 'image/x-icon', href: `${this.merchantDetails.merchant_logourl}` },
    //       { rel: "stylesheet", href: "/css/style.css" },
          { rel: "stylesheet", href: "/css/bootstrap.min.css" },
          { rel: "stylesheet", href: "/css/bootstrapstyle.css" }
      ],
      script: [
        // {src: 'https://developers.kakao.com/sdk/js/kakao.js', body: true},
        // {src: 'https://www.hyatt.com/hds/bookends/1.latest/ecommerce/en-US/bookends.min.js', body: true},
        //{src: 'https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-606e8a1a85aa8f13', body: true},
        { src: "/js/jquery.min.js", body: true },
        { src: "/js/bootstrap.bundle.min.js", body: true },
      ],
      __dangerouslyDisableSanitizers: ['script', 'noscript', 'innerHTML'],
    }
   },
  methods: {
    goSignin() {
      var url = 'https://www.hyatt.com/partner/login?client_id=fce4bd7a62773edad34e48c207e887f3&redirect_uri=https%3A%2F%2Fexperiences.hyatt.com%2Fsignin-hyatt&state=IfyI_parbe6jt4mXpNNoypqC1OKnMQLPmPkNOl2ucqDjQxOYRhQcK_yelgOkUkaNrZGkADEVUe1WeYzXsXJfaY_2L6cIhEInRL9OBejHGuN4Vsh-uBivfucGN69J4NnPLrEGFX3cUJUWOfzahvVWg0bj6dM2CiSsBKQw1QhztcIr8M4nkvcF3y39tUdLc25cch1rLW0P1QeGdGVO-swc9jHN_IN7TK0pZW05FMifL5scOWs2'
      window.location.replace(url)
    },
    countdown(){
          var min15 = 15 * 60 * 1000;
          var countdown = this.$store.state.sessionStorage.timerTime
           var cart_clear = `${this.$t('cart_clear.cart_clear')}`;
            var time_remaining = `${this.$t('cart_clear.time_remaining')}`;
          var timerId = setInterval(function(){
            var timevalue = min15 - (Date.now() - countdown);
            var _min = Math.floor(timevalue / (60 * 1000));
            var _sec = Math.floor((timevalue - (_min * 60 * 1000)) / 1000);
            var min = ("0" + _min).slice(-2);
            var sec = ("0" + _sec).slice(-2);
            if (timevalue <= 0 || isNaN(timevalue)) {
              $("#counter").html(cart_clear);
              $("#counter1").html(cart_clear);
                hello()
                clearInterval(timerId);
                
                
            } else {
                 var text = `${time_remaining} ${min}:${sec}` //+ "" + min + ":" + sec
                $("#counter").html(text);
                $("#counter1").html(text);
            }
          }, 1000);
          
        let  hello = () => {
          this.$bvModal.show('clear-cart');
          setTimeout(() => {
                this.CancelShop()
          }, 15 * 60 * 1000);
          // window.location.reload()
          // this.$store.commit('sessionStorage/assign', '')
        }
      },
      ContinueShop(){
      this.countClearFlag = true
      this.$bvModal.hide('clear-cart');
      var timerTime = Date.now();
      this.$store.commit('sessionStorage/assignStartTimer', timerTime);
      this.countdown()
    },
    CancelShop(){
      this.countClearFlag = false
      this.$bvModal.hide('clear-cart');
      this.$store.commit('sessionStorage/clearTimeout', '');
      this.selectedCoupon_count = 0
      this.$store.commit('sessionStorage/assignCountDownFlag', false);
      this.countdownFlag = false
      window.location.replace('/'+this.countryCode)
    },
    orderReview() {
      if (this.$store.state.sessionStorage.CampaignCartList.length > 0) {
        var url = `/${this.$route.params.country}/review`
        window.location.replace(url)
      } else {
        alert(this.$t('alerts.cart_is_empty'))
      }
      
    },
        changeLang: function(lang) {
            this.$i18n.locale = lang;
            window.location.reload();
        },
        header(){
        var dropdown = document.querySelectorAll('.dropdown');
        var dropdownArray = Array.prototype.slice.call(dropdown,0);
        dropdownArray.forEach(function(el){
          var button = el.querySelector('a[data-toggle="dropdown"]'),
              menu = el.querySelector('.dropdown-menu'),
              arrow = button.querySelector('i.icon-arrow');

          button.onclick = function(event) {
            if(!menu.hasClass('show')) {
              menu.classList.add('show');
              menu.classList.remove('hide');
              arrow.classList.add('open');
              arrow.classList.remove('close');
              event.preventDefault();
            }
            else {
              menu.classList.remove('show');
              menu.classList.add('hide');
              arrow.classList.remove('open');
              arrow.classList.add('close');
              event.preventDefault();
            }
          };
        })

        Element.prototype.hasClass = function(className) {
            return this.className && new RegExp("(^|\\s)" + className + "(\\s|$)").test(this.className);
        };

        },
        language(){
          $(document).ready(function(){
          // $(".lang-button").click(function(){
            $(".navbar-nav1").css({"display": "none"});
            $(".navbar-nav2").css({"display": "flex"});
            $(".back-btn").css({"display": "flex"});
            $("#close-btn").css({"display": "none"});
             
          // });
        });
        },
        backbtn(){
          $(document).ready(function(){
          // $(".back-btn").click(function(){
            $(".navbar-nav1").css({"display": "flex"});
            $(".navbar-nav2").css({"display": "none"});
            $(".back-btn").css({"display": "none"});
            $("#close-btn").css({"display": "flex"});

          // });
        });
        },
        goback: function() {
            // const params = new URLSearchParams(window.location.search)
            // window.history.back(-1);
            // this.$nuxt.$router.push({path: '/'+this.country})
            window.location.replace('/'+this.countryCode)
        },
        openNav() {
          $("#mySidebar").css({"width": "320px"});
            $("#main").css({"marginLeft": "250px"});
          // document.getElementById("mySidebar").style.width = "250px";
          // document.getElementById("main").style.marginLeft = "250px";
        },
         closeNav() {
           $("#mySidebar").css({"width": "0"});
            $("#main").css({"marginLeft": "0"});
          // document.getElementById("mySidebar").style.width = "0";
          // document.getElementById("main").style.marginLeft= "0";
        },
        lang_change: function(lang_code){
      this.$store.commit('sessionStorage/assignSelectLang', lang_code);
      window.location.reload()
      // MultiLang(lang_code)
    },
    },
    mounted: function() {
        console.log("this.$nuxt.$route.name ", this.$nuxt.$route)
        this.header();
        this.merchantSettings = this.$store.state.sessionStorage.merchantSettings;
        console.log('this.merchantSettings',this.merchantSettings);
        if(this.merchantSettings !== undefined && this.merchantSettings !== null){
       if (this.merchantSettings) {
        if (this.merchantSettings.selected_language) {
          var selected_language = this.merchantSettings.selected_language;
              var selectLanguage = [];
        if (selected_language.kr) {
            var language_object_kr = {};
        }
        if (selected_language.en) {
            var language_object_en = {};
        }
        if (selected_language.zh_Hant) {
            var language_object_zh_Hant = {};
        }
        if (selected_language.zh_Hans) {
            var language_object_zh_Hans = {};
        }
        if (selected_language.id) {
            var language_object_id = {};
        }
        if (selected_language.th) {
            var language_object_th = {};
        }
        if (selected_language.ja) {
            var language_object_ja = {};
        }
       if (selected_language.kr) {
            language_object_kr.name = "Korean (한국어)";
            language_object_kr.code = "kr";
            language_object_kr.code_align = "F";
            selectLanguage.push(language_object_kr);
        }

        if (selected_language.en) {
            language_object_en.name = "English";
            language_object_en.code = "en";
            language_object_en.code_align = "A";
            selectLanguage.push(language_object_en);
        }

        if (selected_language.zh_Hant) {
            language_object_zh_Hant.name = "Chinese Traditional (繁體中文)";
            language_object_zh_Hant.code = "zh_Hant";
            language_object_zh_Hant.code_align = "C";
            selectLanguage.push(language_object_zh_Hant);
        }

        if (selected_language.zh_Hans) {
            language_object_zh_Hans.name = "Chinese Simplified (簡體中文)";
            language_object_zh_Hans.code = "zh_Hans";
            language_object_zh_Hans.code_align = "D";
            selectLanguage.push(language_object_zh_Hans);
        }

        if (selected_language.id) {
            language_object_id.name = "Bahasa Indonesia";
            language_object_id.code = "id";
            language_object_id.code_align = "B";
            selectLanguage.push(language_object_id);
        }

        if (selected_language.th) {
            language_object_th.name = "Thai (ภาษาไทย)";
            language_object_th.code = "th";
            language_object_th.code_align = "G";
            selectLanguage.push(language_object_th);
        }

        if (selected_language.ja) {
            language_object_ja.name = "Japanese (日本語)";
            language_object_ja.code = "ja";
            language_object_ja.code_align = "E";
            selectLanguage.push(language_object_ja);
        }

              this.selectedLang = selectLanguage;
              this.selectedLang.sort((a,b) => a.code_align.localeCompare(b.code_align));
        }
      }
      
      console.log('this.selectedLang',this.selectedLang);
     }
        // this.subheader();
        
        $(".loyal-btn").click(function(){
        $(".nav-ul").toggle();
    });
    }
}
