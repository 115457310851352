
// import AppHeader from '~/components/AppHeader';
import Footer from '~/components/Footer';

export default {
  data() {
    return {
      MerchantSettings:''
    }
  },
  components: {
    // AppHeader,
    Footer
  },
  mounted() {
    this.MerchantSettings = this.$store.state.sessionStorage.merchantSettings;
  }
}
