import { render, staticRenderFns } from "./layoutm2.vue?vue&type=template&id=7d38c622&"
import script from "./layoutm2.vue?vue&type=script&lang=js&"
export * from "./layoutm2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports