
  export default {
    props: ['error'],
    layout: 'error' ,// you can set a custom layout for the error page
    data() {
      return{
        country:'',
        MerchantSettings:'',
        omise_transaction_id:''
      }
    },
    // asyncData({query, params, store}) {
    //     try {
    //         return {
    //             country: store.state.sessionStorage.countryCode,
    //             accessToken: store.state.sessionStorage.token
    //         }
    //     }
    //     catch(e) {
    //         return {
    //             country: store.state.sessionStorage.countryCode,
    //             accessToken: store.state.sessionStorage.token
    //         }
    //     }
        
    // },
    mounted(){
      this.omise_transaction_id = this.$store.state.sessionStorage.omise_transaction_id
      this.country = this.$store.state.sessionStorage.countryCode;
      console.log('this.country',this.country);
      this.MerchantSettings = this.$store.state.sessionStorage.merchantSettings;
     console.log('MerchantSettings',this.MerchantSettings);
     if(this.MerchantSettings){
        $('body').append(this.MerchantSettings.body_script_end);
        $('body').prepend(this.MerchantSettings.body_script_start);
        $('head').append(this.MerchantSettings.header_script)
     }
    }
  }
