import { render, staticRenderFns } from "./layoutb.vue?vue&type=template&id=1fd7f4a3&"
import script from "./layoutb.vue?vue&type=script&lang=js&"
export * from "./layoutb.vue?vue&type=script&lang=js&"
import style0 from "./layoutb.vue?vue&type=style&index=0&id=1fd7f4a3&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeader: require('/usr/src/hyattv2prod/components/AppHeader.vue').default,Footer: require('/usr/src/hyattv2prod/components/Footer.vue').default})
