// plugins/i18n.js

import Vue from "vue";
import VueI18n from "vue-i18n";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Tell Vue to use our plugin
Vue.use(VueI18n);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

export default ({ app }) => {
    // Set the i18n instance on app
    // This way we can use it globally in our components through this.$i18n
    app.i18n = new VueI18n({
        // Set the initial locale
        locale: "en-US",
        lazy: true,
        // Set the fallback locale in case the current locale can't be found
        fallbackLocale: "en-US",

        // Associate each locale to a content file    
        messages: {
            'en-US': require("~/locales/content-en.json"),
            'ko-KR': require("~/locales/content-kr.json"),
            'ja-JP': require("~/locales/content-jp.json"),
            'zh-Hans': require("~/locales/content-zh-hans.json"),
            'zh-Hant': require("~/locales/content-zh-hant.json"),
            'vi-VM': require("~/locales/content-vi.json"),
            'th-TH': require("~/locales/content-th.json"),
        }
    });
};