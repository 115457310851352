import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3cb49618 = () => interopDefault(import('../pages/country.vue' /* webpackChunkName: "pages/country" */))
const _09f3ebfe = () => interopDefault(import('../pages/heartbeat/index.vue' /* webpackChunkName: "pages/heartbeat/index" */))
const _6f15398b = () => interopDefault(import('../pages/hongkong.vue' /* webpackChunkName: "pages/hongkong" */))
const _5450e199 = () => interopDefault(import('../pages/maintanance.vue' /* webpackChunkName: "pages/maintanance" */))
const _7ca66f0a = () => interopDefault(import('../pages/notsupport.vue' /* webpackChunkName: "pages/notsupport" */))
const _05d44216 = () => interopDefault(import('../pages/thailand.vue' /* webpackChunkName: "pages/thailand" */))
const _28ee1bd4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1ad32288 = () => interopDefault(import('../pages/_country/index.vue' /* webpackChunkName: "pages/_country/index" */))
const _04975e6f = () => interopDefault(import('../pages/_country/confirmation.vue' /* webpackChunkName: "pages/_country/confirmation" */))
const _f12c1e12 = () => interopDefault(import('../pages/_country/default.vue' /* webpackChunkName: "pages/_country/default" */))
const _35fc3c26 = () => interopDefault(import('../pages/_country/expiry.vue' /* webpackChunkName: "pages/_country/expiry" */))
const _2a7a2ec9 = () => interopDefault(import('../pages/_country/notfound.vue' /* webpackChunkName: "pages/_country/notfound" */))
const _751418ae = () => interopDefault(import('../pages/_country/passwordprotected.vue' /* webpackChunkName: "pages/_country/passwordprotected" */))
const _069902cf = () => interopDefault(import('../pages/_country/recovery.vue' /* webpackChunkName: "pages/_country/recovery" */))
const _b6b77cdc = () => interopDefault(import('../pages/_country/review.vue' /* webpackChunkName: "pages/_country/review" */))
const _765e3e86 = () => interopDefault(import('../pages/_country/terms.vue' /* webpackChunkName: "pages/_country/terms" */))
const _5efe5da6 = () => interopDefault(import('../pages/_country/unsuccessful.vue' /* webpackChunkName: "pages/_country/unsuccessful" */))
const _dca92b94 = () => interopDefault(import('../pages/_country/events/reschedule.vue' /* webpackChunkName: "pages/_country/events/reschedule" */))
const _318a5294 = () => interopDefault(import('../pages/_country/campaigns/_campaign_id/index.vue' /* webpackChunkName: "pages/_country/campaigns/_campaign_id/index" */))
const _04498c46 = () => interopDefault(import('../pages/_country/events/_event_id/index.vue' /* webpackChunkName: "pages/_country/events/_event_id/index" */))
const _7717729d = () => interopDefault(import('../pages/_country/events/_event_id/changebooking.vue' /* webpackChunkName: "pages/_country/events/_event_id/changebooking" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/country",
    component: _3cb49618,
    name: "country"
  }, {
    path: "/heartbeat",
    component: _09f3ebfe,
    name: "heartbeat"
  }, {
    path: "/hongkong",
    component: _6f15398b,
    name: "hongkong"
  }, {
    path: "/maintanance",
    component: _5450e199,
    name: "maintanance"
  }, {
    path: "/notsupport",
    component: _7ca66f0a,
    name: "notsupport"
  }, {
    path: "/thailand",
    component: _05d44216,
    name: "thailand"
  }, {
    path: "/",
    component: _28ee1bd4,
    name: "index"
  }, {
    path: "/:country",
    component: _1ad32288,
    name: "country"
  }, {
    path: "/:country/confirmation",
    component: _04975e6f,
    name: "country-confirmation"
  }, {
    path: "/:country/default",
    component: _f12c1e12,
    name: "country-default"
  }, {
    path: "/:country/expiry",
    component: _35fc3c26,
    name: "country-expiry"
  }, {
    path: "/:country/notfound",
    component: _2a7a2ec9,
    name: "country-notfound"
  }, {
    path: "/:country/passwordprotected",
    component: _751418ae,
    name: "country-passwordprotected"
  }, {
    path: "/:country/recovery",
    component: _069902cf,
    name: "country-recovery"
  }, {
    path: "/:country/review",
    component: _b6b77cdc,
    name: "country-review"
  }, {
    path: "/:country/terms",
    component: _765e3e86,
    name: "country-terms"
  }, {
    path: "/:country/unsuccessful",
    component: _5efe5da6,
    name: "country-unsuccessful"
  }, {
    path: "/:country/events/reschedule",
    component: _dca92b94,
    name: "country-events-reschedule"
  }, {
    path: "/:country/campaigns/:campaign_id",
    component: _318a5294,
    name: "country-campaigns-campaign_id"
  }, {
    path: "/:country/events/:event_id",
    component: _04498c46,
    name: "country-events-event_id"
  }, {
    path: "/:country/events/:event_id?/changebooking",
    component: _7717729d,
    name: "country-events-event_id-changebooking"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
