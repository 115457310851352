let faroInstance = null;

const initializeFaro = async (app, inject) => {
  console.log('process.env',process.env);
  if (process.client) {
    const { getWebInstrumentations, initializeFaro } = await import('@grafana/faro-web-sdk');
    // const { TracingInstrumentation } = await import('@grafana/faro-web-tracing');

    faroInstance = initializeFaro({
      url: 'https://faro-collector-prod-ap-southeast-1.grafana.net/collect/f5a3db350ccab295395d2bbff5b9831a',
      app: {
        name: 'hyatt-prod',
        version: '1.0.0',
        environment: 'production'
      },
      instrumentations: [
        ...getWebInstrumentations(),
        // new TracingInstrumentation(),
      ],
    });

    inject('faro', faroInstance);
  }
};

export default async ({ app }, inject) => {
  await initializeFaro(app, inject);
};