import { render, staticRenderFns } from "./layoutm1.vue?vue&type=template&id=23e9d398&"
import script from "./layoutm1.vue?vue&type=script&lang=js&"
export * from "./layoutm1.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeaderm1: require('/usr/src/hyattv2prod/components/AppHeaderm1.vue').default,Footer: require('/usr/src/hyattv2prod/components/Footer.vue').default})
