export default async function ({req, $axios, redirect}) {
    var requestURL = `/maintanance`;
    if (req && process.server) {
      if(process.env.ENABLE_MAINTENANCE && process.env.ENABLE_MAINTENANCE === '1') {
        console.log( "Process Server", req.headers, "Env= ",process.env.NODE_ENV, process.env.ENV);
        
        if(process.env.NODE_ENV == "production")
          requestURL = `https://${req.headers.host}/maintanance`;
        
          return redirect(requestURL);
      }
      else {
        return true
      }
        
         
    }
    // return true;
    
}